<template>
  <div>
    <p v-if="fetching">Loading...</p>
    <template v-else>
      <Status class="s-text-secondary s-mb-6" icon="check" message="跟團成功！" />
      <div class="s-mb-3 s-space-y-3 s-text-center">
        <DescriptionList title="商品名稱">{{ product.title }}</DescriptionList>
        <DescriptionList title="款式">{{ spec.sku_name }}</DescriptionList>
      </div>
      <p class="s-text-center s-text-gray s-mb-10">團購 ID：{{ groupBuying.groupbuying_number }}</p>
      <div class="s-border s-border-gray-light s-rounded-lg s-px-2 s-py-3">
        <p class="s-text-center s-text-secondary s-mb-3">
          <CautionIcon />
        </p>
        <p>請注意：跟團成功不等同完成購買，請密切留意成團訊息，<span class="s-text-secondary">並在成團後儘速完成結帳</span>，系統才會為您安排出貨喔！</p>
      </div>
    </template>
  </div>
</template>

<script>
  import Status from '@/components/Page/Liff/GroupBuying/Status'
  import DescriptionList from '@/components/Page/Liff/GroupBuying/DescriptionList'
  import CautionIcon from '@/components/Page/Liff/GroupBuying/CautionIcon'
  import fetchGroupBuying from '@/mixins/liff/groupBuying/fetchGroupBuying'

  export default {
    mixins: [fetchGroupBuying],
    components: { Status, DescriptionList, CautionIcon },
  }
</script>
