import { mapActions } from 'vuex'
import withFetcher from '../withFetcher'
import groupBuying from './groupBuying'

export default {
  mixins: [withFetcher, groupBuying],
  data: () => ({
    groupBuying: {},
    error: '',
  }),
  methods: {
    ...mapActions({
      getGroupBuying: 'liffGroupBuying/getGroupBuying',
    }),
    async fetch () {
      this.groupBuying = await this.getGroupBuying({ id: this.$route.params.id })
    },
  },
}
