<template>
  <dl>
    <dt class="s-mb-3">{{ title }}</dt>
    <dd class="s-text-xl s-text-gray-darker s-font-semibold" :class="{ 's-mb-2': $slots.info }"><slot /></dd>
    <small class="s-text-sm s-font-semibold" v-if="$slots.info"><slot name="info" /></small>
  </dl>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: true,
      },
    },
  }
</script>
