<template>
  <div class="s-flex-center s-flex-col">
    <div class="s-mb-6">
      <svg v-if="icon === 'check'" width="78" height="78" viewBox="0 0 78 78" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fill-rule="evenodd">
          <path d="M38.75 77.5c6.98 0 13.437-1.745 19.375-5.235 5.937-3.49 10.65-8.203 14.14-14.14 3.49-5.938 5.235-12.396 5.235-19.375 0-6.98-1.745-13.438-5.235-19.375-3.49-5.938-8.203-10.651-14.14-14.14C52.187 1.744 45.729 0 38.75 0c-6.98 0-13.438 1.745-19.375 5.234-5.938 3.49-10.651 8.203-14.14 14.141C1.744 25.312 0 31.771 0 38.75c0 6.98 1.745 13.437 5.234 19.375 3.49 5.937 8.203 10.65 14.141 14.14 5.937 3.49 12.396 5.235 19.375 5.235zm0-5c-6.042 0-11.667-1.51-16.875-4.531s-9.323-7.11-12.344-12.266C6.511 50.547 5 44.922 5 38.828c0-6.094 1.51-11.745 4.531-16.953s7.11-9.323 12.266-12.344C26.953 6.511 32.578 5 38.672 5c6.094 0 11.745 1.51 16.953 4.531s9.323 7.11 12.344 12.266c3.02 5.156 4.53 10.781 4.53 16.875 0 6.094-1.51 11.745-4.53 16.953-3.021 5.208-7.11 9.323-12.266 12.344-5.156 3.02-10.807 4.53-16.953 4.53z" fill-rule="nonzero" />
          <path d="M32.916 54.75c.413 0 .744-.164.993-.49l22.469-21.954c.248-.245.372-.572.372-.98a1.78 1.78 0 0 0-.372-1.104l-1.118-.982a1.185 1.185 0 0 0-.993-.49 1.83 1.83 0 0 0-1.117.368l-20.234 19.99-8.566-8.585a1.83 1.83 0 0 0-1.117-.368c-.414 0-.745.123-.993.368l-1.118.982a1.78 1.78 0 0 0-.372 1.103c0 .41.124.736.372.981l10.676 10.67c.331.327.704.49 1.118.49z" />
        </g>
      </svg>
      <svg v-if="icon === 'clock'" width="78" height="78" viewBox="0 0 78 78" xmlns="http://www.w3.org/2000/svg">
        <path d="M39.125 78c6.195 0 11.908-1.524 17.139-4.57 5.23-3.047 9.369-7.186 12.416-12.416 3.046-5.23 4.57-10.944 4.57-17.14 0-4.163-.736-8.175-2.21-12.034-1.472-3.86-3.528-7.313-6.17-10.36l2.743-2.59c.305-.406.457-.863.457-1.37 0-.508-.152-.915-.457-1.22l-.914-.913c-.305-.305-.71-.457-1.219-.457-.507 0-.965.152-1.37.457l-2.59 2.742c-5.79-5.078-12.493-7.871-20.11-8.379V4.875h3.809c.507 0 .94-.178 1.295-.533.355-.356.533-.787.533-1.295V1.828c0-.508-.178-.94-.533-1.295A1.763 1.763 0 0 0 45.219 0H33.03c-.508 0-.94.178-1.295.533a1.763 1.763 0 0 0-.533 1.295v1.219c0 .508.178.94.533 1.295.356.355.787.533 1.295.533h3.504v5.027c-5.789.407-11.096 2.184-15.92 5.332-4.824 3.149-8.633 7.237-11.426 12.264C6.396 32.525 5 37.984 5 43.875c0 6.195 1.523 11.908 4.57 17.139 3.047 5.23 7.186 9.369 12.416 12.416C27.216 76.476 32.93 78 39.125 78zm0-4.875c-5.281 0-10.156-1.32-14.625-3.961-4.469-2.64-8.023-6.195-10.664-10.664-2.64-4.469-3.961-9.344-3.961-14.625 0-5.281 1.32-10.156 3.96-14.625 2.642-4.469 6.196-8.023 10.665-10.664 4.469-2.64 9.344-3.961 14.625-3.961 5.281 0 10.156 1.32 14.625 3.96 4.469 2.642 8.023 6.196 10.664 10.665 2.64 4.469 3.96 9.344 3.96 14.625 0 5.281-1.32 10.156-3.96 14.625-2.64 4.469-6.195 8.023-10.664 10.664-4.469 2.64-9.344 3.96-14.625 3.96zm.61-19.5c.507 0 .939-.178 1.294-.533.356-.356.533-.787.533-1.295V26.203c0-.508-.177-.94-.533-1.295a1.763 1.763 0 0 0-1.295-.533h-1.218c-.508 0-.94.178-1.295.533a1.763 1.763 0 0 0-.534 1.295v25.594c0 .508.178.94.534 1.295.355.355.787.533 1.295.533h1.218z" fill="currentColor" fill-rule="nonzero" />
      </svg>
      <svg v-if="icon === 'forbidden'" width="78" height="78" viewBox="0 0 78 78" xmlns="http://www.w3.org/2000/svg">
        <path d="M39.5 77c6.934 0 13.35-1.734 19.25-5.2 5.9-3.468 10.582-8.15 14.05-14.05 3.466-5.9 5.2-12.316 5.2-19.25 0-6.934-1.734-13.35-5.2-19.25-3.468-5.9-8.15-10.582-14.05-14.05C52.85 1.735 46.434 0 39.5 0c-6.934 0-13.35 1.734-19.25 5.2-5.9 3.468-10.582 8.15-14.05 14.05C2.735 25.15 1 31.566 1 38.5c0 6.934 1.734 13.35 5.2 19.25 3.468 5.9 8.15 10.582 14.05 14.05 5.9 3.466 12.316 5.2 19.25 5.2zm.078-4.968c-6.055 0-11.67-1.5-16.844-4.502-5.175-3.001-9.263-7.063-12.264-12.186-3.002-5.123-4.502-10.712-4.502-16.766 0-6.055 1.5-11.67 4.502-16.844 3.001-5.175 7.063-9.263 12.186-12.264 5.123-3.002 10.712-4.502 16.766-4.502 6.055 0 11.67 1.5 16.844 4.502 5.175 3.001 9.263 7.063 12.264 12.186 3.002 5.123 4.502 10.738 4.502 16.844 0 6.003-1.5 11.591-4.502 16.766-3.001 5.175-7.063 9.263-12.186 12.264-5.123 3.002-10.712 4.502-16.766 4.502zm17.93-30.738c.518 0 .957-.18 1.32-.543.362-.362.543-.802.543-1.32V37.57c0-.518-.181-.958-.543-1.32a1.797 1.797 0 0 0-1.32-.543H21.492c-.518 0-.957.18-1.32.543a1.797 1.797 0 0 0-.543 1.32v1.862c0 .518.181.958.543 1.32.363.362.802.543 1.32.543h36.016z" fill="currentColor" fill-rule="nonzero" />
      </svg>
      <svg v-if="icon === 'error'" width="79" height="78" viewBox="0 0 79 78" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
          <path d="M39.5 77.5c6.98 0 13.437-1.745 19.375-5.235 5.937-3.49 10.65-8.203 14.14-14.14 3.49-5.938 5.235-12.396 5.235-19.375 0-6.98-1.745-13.438-5.235-19.375-3.49-5.938-8.203-10.651-14.14-14.14C52.937 1.744 46.479 0 39.5 0c-6.98 0-13.438 1.745-19.375 5.234-5.938 3.49-10.651 8.203-14.14 14.141C2.494 25.312.75 31.771.75 38.75c0 6.98 1.745 13.437 5.234 19.375 3.49 5.937 8.203 10.65 14.141 14.14C26.062 75.755 32.521 77.5 39.5 77.5zm0-5c-6.042 0-11.667-1.51-16.875-4.531s-9.323-7.11-12.344-12.266C7.261 50.547 5.75 44.922 5.75 38.828c0-6.094 1.51-11.745 4.531-16.953s7.11-9.323 12.266-12.344C27.703 6.511 33.328 5 39.422 5c6.094 0 11.745 1.51 16.953 4.531s9.323 7.11 12.344 12.266c3.02 5.156 4.53 10.781 4.53 16.875 0 6.094-1.51 11.745-4.53 16.953-3.021 5.208-7.11 9.323-12.266 12.344-5.156 3.02-10.807 4.53-16.953 4.53z" fill="currentColor" fill-rule="nonzero"/>
          <g stroke="currentColor" stroke-linecap="round" stroke-width="5">
            <path d="m28.02 51.062 24.042-24.041M52.416 51.416 28.374 27.374"/>
          </g>
        </g>
      </svg>

    </div>
    <p class="s-text-caption s-font-medium">{{ message }}</p>
  </div>
</template>

<script>
  export default {
    props: {
      icon: {
        type: String,
        required: true,
      },
      message: {
        type: String,
        required: true,
      },
    }
  }
</script>
